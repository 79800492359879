import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Timeline from '../../../../components/ui/timeline-ru'
import ImageOne from '../../../../data/images/bg/machines/smt/conveyer/pcb-conveyer.jpg'
import ImageTwo from '../../../../data/images/bg/machines/smt/reflow/reflow-oven.jpg'
import ImageThree from '../../../../data/images/bg/machines/smt/conveyer/pcb-loader.jpg'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "ЗАГРУЗКА",
            image: ImageOne,
            title: "Оборудование для загрузки - выгрузки печатных плат",
            desc: "Оборудование для автоматической загрузки печатных плат в линию  и выгрузки печатных плат с линии применяют для полной автоматизации производственной линии. Устройства загрузки / выгрузки полностью исключат ручное перемещение плат и существенно снижает уровень брака на ранних стадия производства. Перемещение печатных плат в устройствах загрузки может осуществляться с накопителей плат или непосредственно со стопки. Использование устройств загрузки / выгрузки позволяет увеличить производительность линии в условиях мелкосерийного многономенклатурного производства на 30%. Это незаменимый атрибут для контрактных производителей и показатель высокой организации производства.",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат",
            lang: "Подробно..."
        },
        {
            year: "СОЕДИНЕНИЕ",
            image: ImageTwo,
            title: "Конвейеры для перемещения печатных плат",
            desc: "Конвейеры для перемещения печатных плат служат для непосредственного соединения единиц технологического оборудования в производственною линию. Оборудование может применятся для создания рабочих мест и визуального контроля. Используются специальные виды конвейер под определенные виды оборудования ( машина пайки волной припоя, автоматическая оптическая инспекция и др.)",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейеры-для-печатных-плат",
            lang: "Подробно..."
        },
        {
            year: "УПРАВЛЕНИЕ",
            image: ImageThree,
            title: "Управление потоком печатных плат в линии",
            desc: "Дополнительные специализированные модули для перемещения плат, которые применяются при особых требования Заказчика, специфики технологических процессов, балансирования и увеличения производительности линии. Перемещение печатных плат с использованием дополнительных модулей позволяет оптимизировать и сбалансировать производственную линию, делая ее совершенной и удобной для работы персонала.",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейерные-системы-для-плат",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "ЗАВАНТАЖЕННЯ",
            image: ImageOne,
            title: "Обладнання для завантаження - вивантаження друкованих плат",
            desc: "Устаткування для автоматичного завантаження друкованих плат в лінію і вивантаження друкованих плат з лінії застосовують для повної автоматизації виробничої лінії. Пристрої завантаження - розвантаження повністю виключають ручне переміщення плат і істотно знижують рівень дефектів на ранніх стадія виробництва. Переміщення друкованих плат в пристроях завантаження може здійснюватися з накопичувачів плат або безпосередньо з пачки. Використання пристроїв завантаження - розвантаження дозволяє збільшити продуктивність лінії в умовах дрібносерійного багато номенклатурного виробництва на 30%. Це незамінний атрибут для контрактних виробників і показник високої організації виробництва.",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат",
            lang: "Докладно..."
        },
        {
            year: "З'ЄДНАННЯ",
            image: ImageTwo,
            title: "Конвеєри для переміщення друкованих плат",
            desc: "Конвеєри для переміщення друкованих плат служать для безпосереднього з'єднання одиниць технологічного обладнання в виробничу лінію. Устаткування може застосовуватися для створення робочих місць і візуального контролю. Використовуються спеціальні види конвеєр під певні види обладнання (машина пайки хвилею припою, автоматична оптична інспекція та ін.)",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєри-для-друкованих-плат",
            lang: "Докладно..."
        },
        {
            year: "КЕРУВАННЯ",
            image: ImageThree,
            title: "Керування потоком друкованих плат в лінії",
            desc: "Додаткові спеціалізовані модулі для переміщення плат, які застосовуються при особливих вимоги Замовника, специфіки технологічних процесів, балансування і збільшення продуктивності лінії. Переміщення друкованих плат з використанням додаткових модулів дозволяє оптимізувати і збалансувати виробничу лінію, роблячи її досконалою та зручною для роботи персоналу.",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєрні-системи-для-плат",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Оборудование для перемещения печатных плат применяется для соединения различных единиц оборудования в производственной линии. Автоматизированное перемещение плат существенно увеличивает производительность линии. Уменьшение и полное исключение ручного перемещения печатных плат снижает уровень брака и расходы на изготовление изделия в целом. Устройства подбираются под требования Заказчика учитывая: характеристики выпускаемого изделия, технологическое оборудование в линии, производственное помещение, бюджет.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Обладнання для переміщення друкованих плат застосовується для з'єднання різних одиниць обладнання у виробничій лінії. Автоматизоване переміщення плат істотно збільшує продуктивність лінії. Зменшення і повне виключення ручного переміщення друкованих плат знижує рівень дефектів і витрати на виготовлення виробу в цілому. Пристрої підбираються під вимоги Замовника враховуючи: характеристики виробу, що випускається, технологічне обладнання в лінії, виробниче приміщення, бюджет.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
